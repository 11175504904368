<script>
import { defineComponent, ref, computed, watch } from 'vue'
import FormItem from '@/components/Input/FormItem.vue'
import Price from '@/components/Product/ProductForm/TypeAndPrice/Price/index.vue'
import SelectPhoto from './SelectPhoto.vue'
import { ValidationProvider } from 'vee-validate';

export default defineComponent({
  components: {
    FormItem,
    Price,
    SelectPhoto,
    ValidationProvider,
  },
  props: {
    initialCollapsed: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      required: true,
    },
    business: {
      type: Object,
      required: true,
    },
    images: {
      type: Array,
      required: true,
    },
    units: {
      type: Array,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    fixUnit: {
      type: Boolean,
      default: false,
    },
    skuExcludesRule: {
      type: Array,
      default: () => [],
    },
    edit: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { emit }) {
    const catalog = ref(props.value)
    watch(() => props.value, () => {
      catalog.value = props.value
    }, { deep: true, immediate: true })
    watch(catalog, () => emit('input', catalog.value), { deep: true })

    const show = ref(String(props.initialCollapsed ? '' : '1'))
    watch(show, () => emit('collapsed', !!show.value))
    const toggleShow = () => {
      show.value = show.value === '1' ? '' : '1'
    }

    const checkSKU = event => {
      emit('checksku', event.target.value)
    }

    const toCurrency = (value) => {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    }

    return {
      catalog,
      show,
      toggleShow,
      checkSKU,
      toCurrency,
    }
  },
})
</script>

<template>
  <a-collapse :active-key="show" expand-icon-position="right" :expand-icon="null" :class="{ collapsed: !show }">
    <div class="variant-table--summary">
      <div v-for="(optionName, index) in Object.values(catalog.option)" :key="index" class="option-name">
        {{ optionName }}
      </div>
      <div class="ml-auto">
        <span v-if="catalog.sku">
          {{ catalog.sku?.toUpperCase() }}
        </span>
        <span v-else style="font-size: 80%; color: #bbb">
          (Not set)
        </span>
      </div>
      <div class="actions">
        <a-switch
          :checked="catalog.status"
          :disabled="$route.query.edit ? true : false"
          size="small"
          @change="(val) => {
            catalog.status = val
            $emit('input:status', val)
          }"
        />
      </div>
      <div class="caret">
        <button type="button" @click="toggleShow">
          <a-icon type="caret-right" :rotate="!!show ? 90 : 0" />
        </button>
      </div>
    </div>
    <a-collapse-panel key="1" class="variant-table--item" :show-arrow="false" force-render>
      <div class="variant-table--detail">
        <div class="d-flex">
          <div class="mr-4" style="width: 125px">
            <ValidationProvider
              v-slot="{ errors }"
              :rules="{ required: catalog.status }"
              :custom-messages="{
                required: 'Pilih minimal 1 gambar produk'
              }"
              immediate
              :name="`Gambar ${value.title}`"
              tag="div"
            >
              <SelectPhoto
                v-model="catalog.images"
                :edit="edit"
                :images="images"
                :class="{ 'has-feedback has-error': errors.length }"
              />
              <div v-if="errors.length" class="text-danger text-small mt-2 ant-form-explain">
                {{ errors[0] }}
              </div>
            </ValidationProvider>
          </div>
          <div class="w-100">
            <div v-if="$route.query.edit">
              <a-col class="mb-2" :span="6">
                <div class="title-detail d-flex justify-content-start mb-2">
                  Nama
                </div>
              </a-col>
              <a-col class="mb-2" :span="18">
                <div class="mb-2">
                  : {{ catalog.title }}
                </div>
              </a-col>
              <a-col class="mb-2" :span="6">
                <div class="title-detail d-flex justify-content-start mb-2">
                  {{ $t('SKU') }} Seller
                </div>
              </a-col>
              <a-col class="mb-2" :span="18">
                <div class="mb-2">
                  : {{ catalog.sku }}
                </div>
              </a-col>
              <a-col class="mb-2" :span="6">
                <div class="title-detail d-flex justify-content-start mb-2">
                  {{ $t('SKU') }} Blibli
                </div>
              </a-col>
              <a-col class="mb-2" :span="18">
                <div class="mb-2">
                  : {{ catalog.sku_blibli }}
                </div>
              </a-col>
              <a-col v-if="!edit" class="mb-2" :span="6">
                <div class="title-detail d-flex justify-content-start mb-2">
                  Harga
                </div>
              </a-col>
              <a-col v-if="!edit" class="mb-2" :span="18">
                <div class="mb-2">
                  : {{ catalog.price.currency === 'IDR' ? 'Rp. ' : '$' }} {{ toCurrency(catalog.price.value) }}
                </div>
              </a-col>
              <a-col v-if="!edit" class="mb-2" :span="6">
                <div class="title-detail d-flex justify-content-start mb-2">
                  Satuan
                </div>
              </a-col>
              <a-col v-if="!edit" class="mb-2" :span="18">
                <div class="mb-2">
                  : {{ catalog.price.uom }}
                </div>
              </a-col>
            </div>
            <div v-else class="w-100">
              <a-row type="flex" :gutter="16" class="mb-3">
                <a-col :xl="{ span: 12 }">
                  <FormItem
                    :rules="{ required: catalog.status, no_space: true, excluded: skuExcludesRule }"
                    :custom-messages="{
                      excluded: 'SKU tidak boleh sama dengan varian lainnya'
                    }"
                    label="SKU"
                    :name="`SKU [${value.title}]`"
                    :class="{ existing: !!catalog.msg }"
                  >
                    <a-spin :spinning="catalog.loading">
                      <a-input
                        v-model="catalog.sku"
                        class="h-48px"
                        style="text-transform: uppercase"
                        :disabled="!!$route.query.id || catalog.loading"
                        @keyup="e => e.target.value = e.target.value.toUpperCase()"
                        @change="() => catalog.msg = ''"
                        @blur="checkSKU"
                      />
                    </a-spin>
                    <span v-if="catalog.msg" style="color: red">{{ catalog.msg }}</span>
                  </FormItem>
                </a-col>
                <a-col :xl="{ span: 12 }">
                  <FormItem rules="" :label="$t('product.factory_sku')" :name="`SKU Pabrik [${value.title}]`">
                    <a-input v-model="catalog.factory_sku" class="h-48px" style="text-transform: uppercase" @keyup="e => e.target.value = e.target.value.toUpperCase()" />
                  </FormItem>
                </a-col>
              </a-row>
            </div>
            <div v-if="!$route.query.edit || edit">
              <Price
                v-model="catalog.price"
                :vat-type="product.detail.vat_type"
                :business="business"
                :units="units"
                :unit="fixUnit ? catalog.price.uom : null"
                :label="`Harga Produk ${catalog.sku}`"
                @changeuom="val => $emit('changeuom', val)"
              />
            </div>
          </div>
        </div>
      </div>
    </a-collapse-panel>
  </a-collapse>
</template>

<style scoped lang="scss">
.caret {

  >div,
  >button {
    background-color: transparent;
    border: 0;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

  }
}
</style>
