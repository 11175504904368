<script>
import { computed, defineComponent, ref, watch } from 'vue'
import FormItem from '@/components/Input/FormItem.vue'
import Price from './Price/index.vue'
import BundleProductInput from '../BundleProductInput/index.vue'

export default defineComponent({
  components: {
    FormItem,
    Price,
    BundleProductInput,
},
  props: {
    business: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    edit: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { emit }) {
    const model = ref(props.value)
    watch(() => props.value, (value) => {
      model.value = value
    }, { immediate: true })
    const item = computed({
      get() {
        return model.value.catalogs.items[0] || {}
      },
      set(value) {
        model.value.catalogs.items[0] = value
      },
    })

    const checkSKU = event => {
      emit('checksku', { sku: event.target.value.toUpperCase(), id: 0 })
    }

    const toCurrency = (value) => {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    }

    return {
      model,
      item,
      checkSKU,
      toCurrency,
    }
  },
})
</script>

<template>
  <div>
    <div>
      <div v-if="$route.query.edit">
        <a-col class="mb-2" :span="6">
          <div class="title-detail d-flex justify-content-start mb-2">
            {{ $t('SKU') }} Seller
          </div>
        </a-col>
        <a-col class="mb-2" :span="18">
          <div class="mb-2">
            : {{ item.sku }}
          </div>
        </a-col>
        <a-col class="mb-2" :span="6">
          <div class="title-detail d-flex justify-content-start mb-2">
            {{ $t('SKU') }} Blibli
          </div>
        </a-col>
        <a-col class="mb-2" :span="18">
          <div class="mb-2">
            : {{ item.sku_blibli }}
          </div>
        </a-col>
        <a-col v-if="!edit && model.catalogs.items.length === 1" class="mb-2" :span="6">
          <div class="title-detail d-flex justify-content-start mb-2">
            Harga
          </div>
        </a-col>
        <a-col v-if="!edit && model.catalogs.items.length === 1" class="mb-2" :span="18">
          <div class="mb-2">
            : {{ item.price.currency === 'IDR' ? 'Rp. ' : '$' }} {{ toCurrency(item.price.value) }}
          </div>
        </a-col>
        <a-col v-if="!edit && model.catalogs.items.length === 1" class="mb-2" :span="6">
          <div class="title-detail d-flex justify-content-start mb-2">
            Satuan
          </div>
        </a-col>
        <a-col v-if="!edit && model.catalogs.items.length === 1" class="mb-2" :span="18">
          <div class="mb-2">
            : {{ item.price.uom }}
          </div>
        </a-col>
      </div>
      <div v-else style="max-width: 600px">
        <a-row type="flex" :gutter="16">
          <a-col :lg="{ span: 12 }">
            <FormItem rules="required|no_space" label="SKU" :class="{ existing: !!item.msg }">
              <a-spin :spinning="item.loading">
                <a-input 
                  v-model="item.sku"
                  :disabled="!!$route.query.id || item.loading"
                  class="h-48px"
                  style="text-transform: uppercase"
                  @keyup="e => e.target.value = e.target.value.toUpperCase()"
                  @change="() => item.msg = ''"
                  @blur="checkSKU"
                />
              </a-spin>
              <span v-if="item.msg" style="color: red">{{ item.msg }}</span>
            </FormItem>
          </a-col>
          <a-col :lg="{ span: 12 }">
            <FormItem rules="" :label="$t('product.factory_sku')">
              <a-input 
                v-model="item.factory_sku"
                class="h-48px"
                style="text-transform: uppercase"
                @keyup="e => e.target.value = e.target.value.toUpperCase()"
              />
            </FormItem>
          </a-col>
        </a-row>
      </div>

      <div v-if="!$route.query.edit || edit">
        <Price
          v-model="item.price"
          :business="business"
          :units="model.units"
          :vat-type="model.detail.vat_type" 
          class="mb-5"
        />
      </div>
    </div>

    <!-- <BundleProductInput
      v-model="model.catalogs.bundling"
      :product="value"
      :business="business"
      class="mb-3"
    /> -->
  </div>
</template>
